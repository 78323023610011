

    import { ComputedRef, defineComponent, PropType, reactive, ref, Ref, watchEffect } from 'vue'
    import { ResponsiveValue, useResponsiveValue } from '../compositions/UseResponsiveValue'
    import { AssetInterface } from '../Interfaces/AssetInterface'
    import Paragraph from './Paragraph.vue'

    type ReactiveWidthValueType = string | ComputedRef<string> | Ref<string>

    interface RootElementInterface {
        width?: ReactiveWidthValueType,
        height?: ReactiveWidthValueType,
        maxWidth?: ReactiveWidthValueType,
        maxHeight?: ReactiveWidthValueType,
    }

    export default defineComponent({
        name: 'Picture',
        components: {
            Paragraph
        },
        props: {
            image: { type: Object as PropType<AssetInterface>, required: true },
            auto: { type: [ Boolean, Array ] as PropType<ResponsiveValue<boolean>>, default: false },
            width: { type: [ String, Number, Array ] as PropType<ResponsiveValue<string | number>>, default: null },
            height: { type: [ String, Number, Array ] as PropType<ResponsiveValue<string | number>>, default: null },
            maxWidth: { type: [ String, Number, Array ] as PropType<ResponsiveValue<string | number>>, default: null },
            maxHeight: { type: [ String, Number, Array ] as PropType<ResponsiveValue<string | number>>, default: null },
            defaultGradient: { type: String, default: null },
            lazy: { type: Boolean, default: true },
            cover: { type: Boolean, default: false },
            coverFixed: { type: Boolean, default: false },
            center: { type: Boolean, default: false },
            overflow: { type: Boolean, default: false }
        },
        setup(props) {

            const imageLoaded = ref(false)
            const removePlaceholder = ref(true)

            function castValueToPixelString(value: string | number): string {

                if (typeof value === 'number') {

                    return `${ value }px`

                }

                return value

            }

            function normalizeValues(value: ResponsiveValue<string | number>): string | string[] {

                if (Array.isArray(value)) {

                    return value.map(castValueToPixelString)

                }

                return castValueToPixelString(value)

            }

            function decideIfNeedsToBeResponsive(value: ResponsiveValue<string | number> | null, defaultValue: string | number): ReactiveWidthValueType {

                if (value === null) {

                    return castValueToPixelString(defaultValue)

                }

                return useResponsiveValue(normalizeValues(value))

            }

            const responsiveAuto = useResponsiveValue(props.auto)

            const assetWidth = props?.image?.desktop_asset?.width ?? props?.image?.mobile_asset?.width
            const assetHeight = props?.image?.desktop_asset?.height ?? props?.image?.mobile_asset?.height

            const possiblyResponsiveWidth = decideIfNeedsToBeResponsive(props.width, assetWidth)
            const possiblyResponsiveMaxWidth = decideIfNeedsToBeResponsive(props.maxWidth, assetWidth)
            const possiblyResponsiveHeight = decideIfNeedsToBeResponsive(props.height, assetHeight)
            const possiblyResponsiveMaxHeight = decideIfNeedsToBeResponsive(props.maxHeight, assetHeight)

            const rootElementStyle: RootElementInterface = reactive({
                width: possiblyResponsiveWidth,
                height: possiblyResponsiveHeight,
                maxWidth: possiblyResponsiveMaxWidth,
                maxHeight: possiblyResponsiveMaxHeight
            })

            watchEffect(() => {

                /**
                 * If Auto is set do not attempt to use a fixed width/height
                 */
                if (responsiveAuto.value === true || props.auto === true) {

                    delete rootElementStyle.width
                    delete rootElementStyle.height
                    delete rootElementStyle.maxWidth
                    delete rootElementStyle.maxHeight

                }

                /**
                 * If auto is a responsive value, dynamically re-bind previous responsive values
                 */
                if (responsiveAuto.value === false) {

                    rootElementStyle.width = possiblyResponsiveWidth
                    rootElementStyle.height = possiblyResponsiveHeight
                    rootElementStyle.maxWidth = possiblyResponsiveMaxWidth
                    rootElementStyle.maxHeight = possiblyResponsiveMaxHeight

                }

            })

            const blurryImage = rootElementStyle

            if (props.coverFixed) {

                rootElementStyle.height = 'auto'

            }

            if (rootElementStyle.height === undefined && props.auto === false) {

                // console.error('Avoid layout shifts, always set at least the height of the image.', props.source)

            }

            return {
                rootElementStyle,
                blurryImage: {
                    background: props.image?.gradient_background ?? props.defaultGradient ?? 'linear-gradient(140deg, #424242, #121212)',
                    ...blurryImage,
                    height: props.coverFixed ? blurryImage.maxHeight : blurryImage.height
                },
                imageLoaded,
                removePlaceholder,
                ontransitionend() {

                    removePlaceholder.value = false

                },
                onLoaded() {

                    imageLoaded.value = true

                }
            }

        }
    })

