import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_ClippingAnimation = _resolveComponent("ClippingAnimation")!
  const _component_CustomCursor = _resolveComponent("CustomCursor")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, { fluid: "" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chunkedData, (chunk, idx) => {
        return (_openBlock(), _createBlock(_component_Grid, {
          key: idx,
          class: _normalizeClass({ 'md:mb-20': idx != _ctx.chunkedData.length - 1 })
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chunk, (project, index) => {
              return (_openBlock(), _createBlock(_component_Column, {
                key: `${project.title}.${index}`,
                span: [ 2, 3 ],
                class: _normalizeClass(["items-start", [
                        idx == _ctx.chunkedData.length - 1 && index == chunk.length - 1 ? 'sm:mb-0' : 'sm:mb-24',
                        { 'md:items-end': index === 1 },
                        { 'md:mt-28': index === 1 && _ctx.secondColumnOffset },
                    ]])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Anchor, {
                    route: {
                        name: _ctx.Route.CaseStudy,
                        meta: { theme: project?.theme?.value === 'dark' ? _ctx.Theme.Dark : _ctx.Theme.Default },
                        params: {
                            slug: project.slug,
                            gradient: project?.list_cover?.gradient_background ?? project?.cover?.gradient_background ?? 'linear-gradient(140deg, #424242, #121212)',
                        },
                    },
                    class: "project__placeholder"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CustomCursor, { text: "View" }, {
                        default: _withCtx(({ eventListeners }) => [
                          _createVNode(_component_ClippingAnimation, { interactive: "" }, {
                            default: _withCtx(() => [
                              (project?.list_cover?.desktop_asset?.is_video)
                                ? (_openBlock(), _createBlock(_component_LoopingVideo, _mergeProps({
                                    key: 0,
                                    source: project?.list_cover?.desktop_asset.permalink,
                                    cover: project?.list_cover?.video_cover
                                  }, eventListeners), null, 16, ["source", "cover"]))
                                : (_openBlock(), _createBlock(_component_Picture, _mergeProps({
                                    key: 1,
                                    image: project?.list_cover,
                                    height: [ '380px', '430px', '540px', '640px' ],
                                    "max-height": [ '380px', '430px', '540px', '640px' ],
                                    width: "100%",
                                    maxWidth: 540,
                                    center: ""
                                  }, eventListeners), null, 16, ["image"]))
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["route"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["project__details", { '--shift': index == 1 }])
                  }, [
                    _createVNode(_component_Anchor, {
                      route: {
                            name: _ctx.Route.CaseStudy,
                            params: {
                                slug: project.slug,
                                gradient: `linear-gradient(${ project?.cover?.degree ?? 140 }deg, ${ project?.cover?.start ?? '#424242' }, ${ project?.cover?.end ?? '#121212' })`
                            }
                        }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: _ctx.useH2ForTitle ? 'h2' : 'h3',
                          size: "h3",
                          class: "pt-8 md:pt-12",
                          innerHTML: project.title
                        }, null, 8, ["tag", "innerHTML"])
                      ]),
                      _: 2
                    }, 1032, ["route"]),
                    _createVNode(_component_Paragraph, {
                      "no-margin": "",
                      class: "mt-2 pb-2 uppercase",
                      innerHTML: project.client_name
                    }, null, 8, ["innerHTML"]),
                    (project.tags)
                      ? (_openBlock(), _createBlock(_component_Paragraph, {
                          key: 0,
                          class: "text-base",
                          innerHTML: _ctx.formattedTags(project.tags)
                        }, null, 8, ["innerHTML"]))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["class"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }))
}