import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "looping-video overflow-hidden" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Picture = _resolveComponent("Picture")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.loading && _ctx.cover)
          ? (_openBlock(), _createBlock(_component_Picture, {
              key: 0,
              width: "100%",
              "max-width": "100%",
              height: "100%",
              "max-height": "100%",
              class: "looping-video__cover",
              "default-gradient": _ctx.defaultGradient,
              image: _ctx.cover
            }, null, 8, ["default-gradient", "image"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("video", {
      ref: "videoReference",
      src: _ctx.source,
      autoplay: "",
      "x5-video-player-type": "h5",
      "x5-playsinline": "true",
      "x5-video-player-fullscreen": "true",
      "X5-video-orientation": "portrait",
      "webkit-playsinline": "",
      playsinline: "",
      muted: "",
      loop: "",
      class: _normalizeClass({ 'w-full': _ctx.fullWidth }),
      onCanplay: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args))),
      onLoadedmetadata: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args)))
    }, null, 42, _hoisted_2)
  ]))
}