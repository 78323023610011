import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["srcset", "type"]
const _hoisted_3 = ["loading", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["picture text-center relative w-full h-full", { 'overflow-hidden': !_ctx.overflow }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-center relative w-full h-full", { 'overflow-hidden': !_ctx.overflow }]),
      style: _normalizeStyle(_ctx.rootElementStyle)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["picture__placeholder", { '--exit': _ctx.imageLoaded }]),
        style: _normalizeStyle(_ctx.blurryImage),
        onTransitionend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ontransitionend && _ctx.ontransitionend(...args)))
      }, null, 38),
      (_ctx.removePlaceholder)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["picture__placeholder", { '--exit': _ctx.imageLoaded }]),
            style: _normalizeStyle(_ctx.blurryImage),
            onTransitionend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ontransitionend && _ctx.ontransitionend(...args)))
          }, null, 38))
        : _createCommentVNode("", true),
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("picture", _hoisted_1, [
            (_ctx.image?.mobile_asset)
              ? (_openBlock(), _createElementBlock("source", {
                  key: 0,
                  media: "(max-width: 767px)",
                  srcset: _ctx.image?.mobile_asset?.permalink,
                  type: _ctx.image?.mobile_asset?.mime_type
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("img", {
              class: _normalizeClass({ 'object-cover': _ctx.cover }),
              loading: _ctx.lazy ? 'lazy' : null,
              style: _normalizeStyle(_ctx.rootElementStyle),
              src: _ctx.image?.desktop_asset?.permalink ?? _ctx.image?.permalink,
              alt: "",
              onLoad: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args)))
            }, null, 46, _hoisted_3)
          ]))
        : _createCommentVNode("", true)
    ], 6),
    (_ctx.image?.caption || _ctx.$slots.caption)
      ? (_openBlock(), _createBlock(_component_Paragraph, {
          key: 0,
          class: "picture__caption leading-none my-5"
        }, {
          default: _withCtx(() => [
            (_ctx.image?.caption)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.image.caption), 1)
                ], 64))
              : _renderSlot(_ctx.$slots, "caption", { key: 1 })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 2))
}